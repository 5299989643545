<template>
  <v-card tile class="footer-backround">
    <v-sheet max-width="1180" class="mx-auto px-4 py-4 footer-backround">
      <div class="d-flex align-center">
        <img height="40px" width src="@/assets/images/amogawhitelogo.svg" alt="amogaLogo"/>
        <span class="ml-3 font-weight-bold body-1 white--text">{{title}}</span>
      </div>
      <v-row class="my-4">
        <v-col cols="5" class="pa-0">
          <p class="px-4 mb-0 subtitle-1 footerClr">
            {{description}}
          </p>
        </v-col>
        <v-col class="pa-0">
          <ul class="list-style-none">
            <p class="font-weight-bold mb-2 subtitle-1 white--text">Company</p>
            <li class="py-1 subtitle-2 footerList footerClr" v-for="item in company" :key="item">{{item}}</li>
          </ul>
        </v-col>
        <v-col class="pa-0">
          <ul class="list-style-none">
            <p class="font-weight-bold mb-2 subtitle-1 white--text">Options</p>
            <li class="py-1 subtitle-2 footerList footerClr" v-for="option in options" :key="option">{{option}}</li>
          </ul>
        </v-col>
         <v-col class="pa-0">
          <ul class="list-style-none">
            <p class="font-weight-bold mb-2 subtitle-1 white--text">Charts</p>
            <li class="py-1 subtitle-2 footerList footerClr" v-for="chart in charts" :key="chart">{{chart}}</li>
          </ul>
        </v-col>
         <v-col class="pa-0">
          <ul class="list-style-none">
            <p class="font-weight-bold mb-2 subtitle-1 white--text">Events</p>
            <li class="py-1 subtitle-2 footerList footerClr" v-for="event in events" :key="event">{{event}}</li>
          </ul>
        </v-col>
      </v-row>
      <v-row class="my-4">
        <v-col cols="5" class="pa-0">
          <p class="px-4 mb-0 subtitle-1 footerClr">
            Stoneage Solutions Pvt Ltd,<br />125, 126, Anand Nagar,<br />Thoraipakkam,
            Chennai - 600097 <br />hello@amoga.tech
          </p>
        </v-col>
        <v-col class="px-6 py-0">
          <p class="subtitle-1 mb-2 font-weight-bold white--text">
            Find us on
          </p>
           <v-responsive max-width="55%">
          <div class="w-50 d-flex justify-space-between">
            <a href="https://in.linkedin.com/company/stoneage-solutions-private-limited" target="_blank">
            <img height="25px" width="25px" src="@/assets/images/footerIcons/LinkedIn.svg" alt="linkedinLogo"/></a>
            <img height="25px" width="25px" src="@/assets/images/footerIcons/Facebook.svg" alt="FacebookLogo"/>
            <img height="25px" width="25px" src="@/assets/images/footerIcons/Google.svg" alt="googleLogo" />
            <img height="25px" width="25px" src="@/assets/images/footerIcons/Twitter.svg" alt="twitterLogo"/>
            <a target="_blank" href="https://www.youtube.com/channel/UC3T6l4Dy8eWeLl_I_Ns2vfg/featured">
            <img height="25px" width="25px" src="@/assets/images/footerIcons/YouTube.svg" alt="youTubeLogo"/>
            </a>
          </div>
           </v-responsive>
        </v-col>
      </v-row>
    </v-sheet>
    <!-- <div class="d-flex justify-center py-4 white--text">
      <a class="text-decoration-none white--text caption" href="http://stoneagesolutions.com/home" target="_blank" >© 2019-21 Stoneage Solutions Pvt Ltd
      </a>
    </div> -->
  </v-card>
</template>

<script>
export default {
  data: () => ({
     title: "AMOGA",
     description: 'Amoga Technologies builds platforms & investment products to invest better in Indian equities. Amoga is a basket of stocks/ETFs weighted intelligently to reflect an idea',
     company: ['About Us','Terms of Use','Privacy Policy','Disclaimer'],
     options: ['Option Chain','Basket Orders','OI Analytics','Statistics'],
     charts: ['Straddle Charts','Strangle Charts','Payoff Diagram','PCR & MAXPAIN'],
     events: ['FII DII Data','News','Results Calender','Alerts']
  })
};
</script>

<style>
li.footerList{ list-style: none !important;}
.w-50{width:50%}
.footerClr{color: #dfdfdf;}
.footer-backround{background-color: #0f1d4e !important} 
</style>
